import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`We’re going to go 4 days in a row so we can rest tomorrow for
Easter.`}</em></strong></p>
    <p>{`Single Arm DB Snatch Balance 3×3/arm`}</p>
    <p>{`Single Arm DB Full Snatch 3×6/arm`}</p>
    <p>{`OH Band Pull Aparts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 amrap of:`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`20-K2E’s`}</p>
    <p>{`10-Single Arm OHS (5/arm) (40/25 or use what you have)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      